import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }

  const toggableServices = document.querySelectorAll(".toggable-service");

  if (toggableServices.length) {
    toggableServices.forEach(service => {
      service.addEventListener("click", () => {
        service.classList.toggle("active");
      });
    });
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
