// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $(".home-slider-container .inner"),
  testimonials: $(".testimonials-slider-container .inner"),
};

sliders.home.slick({
  dots: false,
  autoplay: true,
  fade: true,
  pauseOnHover: false,
  speed: 700,
});

if (sliders.testimonials) {
  let $status = $(".testimonials-pagingInfo");

  sliders.testimonials.on("init reInit afterChange", function(event, slick, currentSlide) {
    let i = (currentSlide ? currentSlide : 0) + 1;
    $status.html(`<span class="current">${i}</span><span class="separator">/</span><span class="total">${slick.slideCount}</span>`);
  });

  sliders.testimonials.slick({
    dots: false,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 4,
  });
}
