//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from "smooth-scroll";
import { isMobile } from "./helper";

const smoothScroll = new SmoothScroll('a[href*="#"]', {
  ignore: "[data-vc-container]",
  header: isMobile() ? "#mashead" : null,
});

window.addEventListener("load", () => {
  const hash = window.location.hash;
  const anchorSection = hash ? document.querySelector(hash) : null;

  if (anchorSection) {
    smoothScroll.animateScroll(anchorSection);
  }
});
